.wrapper {
  background-color: white;
  padding: 20px;
  @media (min-width: 1025px) {
    transform: scale(0.9);
  }

  @media (max-width: 1025px) {
    padding-top: 60px;
  }
}

.title {
  color: black;
  font-size: 22px;
  font-weight: 500;

  @media (max-width: 1025px) {
    text-align: center;
  }
}

.subtitle {
  @media (max-width: 1025px) {
    text-align: center;
    padding: 0 15px;
  }
  & br {
    @media (max-width: 1025px) {
      display: none;
    }
  }
}

.switchWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1025px) {
    margin-bottom: 10px;
  }
}

.labelLeft {
  display: flex;
  justify-content: flex-end;
  text-align: end;
  align-items: flex-start;
}

.labelRight {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  align-items: flex-start;
}

.labelNotSelected {
  width: calc(100% - 25px);
}

.labelSelected {
  composes: labelNotSelected;
  color: var(--marketplaceColor);
}

.error {
  width: 100%;
  text-align: center;
  color: red;
}

.field {
  margin-top: 20px;
  @media (max-width: 1025px) {
    width: 100%;
  }
}

.buttonsWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }
}

.rowFieldsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1025px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.rowFieldsWrapper > div:first-child {
  @media (min-width: 1025px) {
    margin-right: 10px;
  }
}

.rowFieldsWrapper > div:nth-child(2) {
  @media (min-width: 1025px) {
    margin-left: 10px;
  }
}

.submit {
  margin-left: 10px;
  @media (max-width: 1025px) {
    margin-bottom: 20px;
    margin-left: 0;
  }
}

.cancel {
  background-color: white;
  color: black;
  border: 1px solid #d0d5dd;
  margin-right: 10px;
  @media (max-width: 1025px) {
    margin-right: 0;
  }

  &:hover {
    background-color: black;
    color: white;
  }
}
